import React, { useEffect, useRef, useState } from 'react'
import { DataGrid, GridActionsCellItem, GridCellParams, GridColDef, GridColumnVisibilityModel, GridRowParams, GridSortModel } from '@mui/x-data-grid'
import './ConsultaEmpleados.css'
import CustomSelect, { CustomSelectRef } from '../../components/customSelect/CustomSelect'
import { opcionesFiltroEstados, rowsContratos } from '../../mockedInfo'
import DownloadIcon from '@mui/icons-material/Download';
import CustomButton from '../../components/customButton/CustomButton'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { renderEstadoTags } from '../../utils'
import MuiDataGrid from '../../components/muiDataGrid/MuiDataGrid'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FiniquitoRequestDto } from '../../services/dto/request/finiquitoRequestDto'
import FiniquitoService from '../../services/finiquitoService'
import { FiniquitoFiltersResponseDto, FiniquitoItemResponseDto, FiniquitoResponseDto, FiniquitosResponseDto } from '../../services/dto/response/finiquitoResponseDto'
import CustomDatePicker, { CustomDatePickerRef } from '../../components/customDatePicker/CustomDatePicker'
import SearchInput, { CustomSearchInputRef } from '../../components/searchInput/SearchInput'
import finiquitoService from '../../services/finiquitoService'
import { Modal } from '@mui/material'
import ModalCard from '../../components/modalCard/ModalCard'
import { formatDate, formatDateString } from '../../utils/dateHelper'
import { descargarArchivo } from '../../utils/fileHelper'
import { Estados } from '../../utils/estadoEnum'
import { LoadingCircle } from '../../utils/loadings'


interface Sociedad {
  label: string;
  id: string;
}

const FiniquitosConsulta = () => {
  const estadoSelectRef = useRef<CustomSelectRef>(null);
  const sociedadSelectRef = useRef<CustomSelectRef>(null);
  const rutEmpleadoInputRef = useRef<CustomSearchInputRef>(null);
  const nombreEmpleadoInputRef = useRef<CustomSearchInputRef>(null);
  const fechaDesdeInputRef = useRef<CustomDatePickerRef>(null);
  const fechaHastaInputRef = useRef<CustomDatePickerRef>(null);
  const [finiquitos, setFiniquitos] = useState<FiniquitoItemResponseDto[]>([]);
  const [cantidadTotalFiniquitos, setCantidadTotalFiniquitos] = useState<number>();
  const [filtro, setFiltro] = useState<FiniquitoRequestDto>({
    currentPage: 1,
    pageSize: 10,
    orderBy: '',
    desc: false
  });
  const [sociedades, setSociedades] = useState<Sociedad[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingDescarga, setLoadingDescarga] = useState(false);
  const [modalData, setModalData] = useState<FiniquitoItemResponseDto>();
  const [estados, setEstados] = useState<{label: any, id: any}[]>([]);

  useEffect(() => {
    FiniquitoService.obtenerFiltros(postFiniquitosFilterRecover, errorRecover)
    mapearEstados();
  }, []);

  const mapearEstados = () => {
    const enumObj = Estados;
    const keys = Object.keys(enumObj).filter(k => typeof enumObj[k as any] === 'number');
    const result = keys.map((key: any) => ({label: key, id: enumObj[key]}))
    setEstados(result)
  }

  useEffect(() => {
    filtrarFiniquitos(filtro)
  }, [filtro]);

  const postFiniquitosRecover = (responseDto: FiniquitosResponseDto) => {
    if (!responseDto.hasErrorMessage && responseDto.finiquitos) {
      setCantidadTotalFiniquitos(responseDto.count);
      setFiniquitos(responseDto.finiquitos);
    }
  }

  const postFiniquitosFilterRecover = (responseDto: FiniquitoFiltersResponseDto) => {
    if (!responseDto.hasErrorMessage) {
      if (responseDto.sociedades) {
        const sociedadesMapeadas: Sociedad[] = responseDto.sociedades.map((x: string) => ({ label: x, id: x }))
        setSociedades(sociedadesMapeadas)
      }
    }
  }

  const errorRecover = (msg: string) => {
    console.log(msg)
  }

  const filtrarFiniquitos = (filter: FiniquitoRequestDto) => {
    FiniquitoService.obtenerTodos(filter, postFiniquitosRecover, errorRecover)
  }

  const handleEstadoChange = (e: any) => {
    const updatedFiltro = {
      ...filtro,
      estado: e
    }
    updateFiltro(updatedFiltro);
  };

  const handleSociedadChange = (e: any) => {
    const updatedFiltro = {
      ...filtro,
      sociedad: e
    }
    updateFiltro(updatedFiltro);
  };

  const handleDatePickerChangeDesde = (e: any) => {
    const formattedDate = e["$d"]?.toISOString().split("T")[0];
    const updatedFiltro = {
      ...filtro,
      fechaDesde: formattedDate
    }
    updateFiltro(updatedFiltro);
  }

  const handleDatePickerChangeHasta = (e: any) => {
    const formattedDate = e["$d"]?.toISOString().split("T")[0];
    const updatedFiltro = {
      ...filtro,
      fechaHasta: formattedDate
    }
    updateFiltro(updatedFiltro);
  }

  const handleRutEmpleado = (e: string) => {
    const updatedFiltro = {
      ...filtro,
      rutTrabajador: e
    }
    updateFiltro(updatedFiltro);
  }

  const handleNombres = (e: string) => {
    const updatedFiltro = {
      ...filtro,
      nombres: e
    }
    updateFiltro(updatedFiltro);
  }

  const handlePageChange = (page: number) => {
    const updatedFiltro = {
      ...filtro,
      currentPage: page + 1
    }
    updateFiltro(updatedFiltro)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    let orderBy = '';
    let desc = false;
    if (model.length > 0) {
      const sortField = model[0].field;
      orderBy = sortField;
      desc = model[0].sort === 'desc';
    }
    const updatedFiltro = {
      ...filtro,
      orderBy: orderBy,
      desc: desc,
    }
    updateFiltro(updatedFiltro);
  };

  const updateFiltro = (filtros: FiniquitoRequestDto) => {
    let updatedFiltro: FiniquitoRequestDto = {
      ...filtros
    }
    if (!updatedFiltro.estado) delete updatedFiltro.estado;
    if (!updatedFiltro.sociedad) delete updatedFiltro.sociedad;
    if (!updatedFiltro.fechaDesde) delete updatedFiltro.fechaDesde;
    if (!updatedFiltro.fechaHasta) delete updatedFiltro.fechaHasta;
    if (!updatedFiltro.rutTrabajador) delete updatedFiltro.rutTrabajador;
    if (!updatedFiltro.nombres) delete updatedFiltro.nombres;
    if (!updatedFiltro.apellidos) delete updatedFiltro.apellidos;
    setFiltro(updatedFiltro);
  }

  const limpiarFiltros = () => {
    if (estadoSelectRef?.current?.clear) estadoSelectRef.current.clear();
    if (sociedadSelectRef?.current?.clear) sociedadSelectRef.current.clear();
    if (rutEmpleadoInputRef?.current?.clear) rutEmpleadoInputRef.current.clear();
    if (nombreEmpleadoInputRef?.current?.clear) nombreEmpleadoInputRef.current.clear();
    if (fechaDesdeInputRef?.current?.clear) fechaDesdeInputRef.current.clear();
    if (fechaHastaInputRef?.current?.clear) fechaHastaInputRef.current.clear();
    updateFiltro({
      currentPage: 1,
      pageSize: 10,
      orderBy: '',
      desc: false
    })
  }

  const descargar = () => {
    setLoadingDescarga(true)
    finiquitoService.descargar(filtro, postDescarga, failGetDescarga);
  }

  const failGetDescarga = () => {

    setLoadingDescarga(false)
    console.log('Descarga fallida');
  }

  const postDescarga = (response: any) => {
    descargarArchivo(response);
    setLoadingDescarga(false)
  }

  const handleOnClickVer = (params: GridRowParams) => {
    finiquitoService.obtener(params.row.id, onPostGetById, errorRecover)
    setDialogOpen(true)
  }

  const onPostGetById = (response: FiniquitoResponseDto) => {
    if (!response.hasErrorMessage) {
      setModalData(response.finiquito)
    }
  }

  const onCloseDialog = () => {
    setDialogOpen(false)
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación del registro',
      flex: 1,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: 'updatedAt',
      headerName: 'Fecha de actualización del registro',
      flex: 1,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: 'nombreEmpresa',
      headerName: 'Nombre Sociedad',
      flex: 1,
    },
    {
      field: 'rutEmpresa',
      headerName: 'Rut Sociedad',
      flex: 1,
    },
    {
      field: 'nombres',
      headerName: 'Nombre Trabajador',
      flex: 1,
    },
    {
      field: 'rutTrabajador',
      headerName: 'Rut Trabajador',
      flex: 1,
    },
    {
      field: 'fechaFinRelaboral',
      headerName: 'Fecha fin relación laboral',
      flex: 1,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: 'causal',
      headerName: 'Causal',
      flex: 1,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      renderCell: (params: any) => renderEstadoTags(params.value),
      flex: 1,
    },
    {
      field: 'mensaje',
      headerName: 'Mensaje',
      flex: 1,
    },
    {
      field: 'ver',
      headerName: 'Ver',
      type: 'actions',
      //No se que hace el boton asi que cambiar el getActions acorde cuando se agregue la logica
      getActions: (params: GridRowParams) => [<GridActionsCellItem
        icon={<VisibilityIcon />}
        label="Delete"
        onClick={() => handleOnClickVer(params)}
      />],
    }
  ];

  return (
    <>
      <div className="main">
        <div className="main-toolbar">
          <div className="main-toolbar-filters">
            {/* TODO: Extraer sidebar de filtros */}
            <div className="main-toolbar-header">
              <p>Filtros</p>
            </div>
            <CustomSelect
              ref={estadoSelectRef}
              label="Estado"
              options={estados}
              onSelect={handleEstadoChange}
            />
            <CustomSelect
              ref={sociedadSelectRef}
              label="Sociedad"
              options={sociedades}
              onSelect={handleSociedadChange}
            />
            <CustomDatePicker ref={fechaDesdeInputRef} placeholder="Fecha desde" onChange={handleDatePickerChangeDesde} />
            <CustomDatePicker ref={fechaHastaInputRef} placeholder="Fecha hasta" onChange={handleDatePickerChangeHasta} />
            <SearchInput label="Rut Empleado" onChange={handleRutEmpleado} ref={rutEmpleadoInputRef} />
            <SearchInput label="Nombre Empleado" onChange={handleNombres} ref={nombreEmpleadoInputRef} />
            <div className="main-toolbar-filters-button">
              <CustomButton
                variant='outlined'
                text="Limpiar Filtros"
                icon={<SettingsBackupRestoreIcon />}
                type="secondary"
                iconPosition='right'
                fontSize='12px'
                handleClick={limpiarFiltros}
              />
            </div>
          </div>
          <CustomButton
            variant='contained'
            text="Descargar tabla"
            icon={<DownloadIcon />}
            type="primary"
            handleClick={descargar}
          />
        </div>
        <MuiDataGrid
          columns={columns}
          rows={finiquitos}
          rowCount={cantidadTotalFiniquitos}
          onPageChange={handlePageChange}
          onSortModelChange={handleSortModelChange} />
      </div>
      <Modal open={dialogOpen} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>

          <ModalCard onClose={onCloseDialog}>
            <h2 style={{ alignSelf: 'center', marginBottom: '10px' }}>Finiquito trabajador {modalData?.rutTrabajador}</h2>
            <div className="modal-ver-container">
              <div className="modal-data-container">
                {modalData?.updatedAt && (
                  <p>
                    <strong>Actualizado</strong><br></br>
                    {formatDate(modalData.updatedAt)}
                  </p>
                )}
                {modalData?.createdAt && (
                  <p>
                    <strong>Creado</strong><br></br>
                    {formatDate(modalData.createdAt)}
                  </p>
                )}
                {modalData?.rutTrabajador && (

                  <p>
                    <strong>Rut Trabajador</strong><br></br>
                    {modalData.rutTrabajador}
                  </p>
                )}
                {modalData?.nombres && (

                  <p>
                    <strong>Nombre Completo</strong><br></br>
                    {modalData.nombres}
                  </p>

                )}
                {modalData?.fechaRetiro && (

                  <p>
                    <strong>Fecha retiro</strong><br></br>
                    {formatDateString(modalData.fechaRetiro)}
                  </p>

                )}
                {modalData?.mensaje && (
                  <p>
                    <strong>Causal</strong><br></br>
                    {modalData.causal}
                  </p>
                )}
                {modalData?.comentario && (
                  <p>
                    <strong>Comentario</strong><br></br>
                    {modalData.comentario}
                  </p>
                )}
                {modalData?.estado && (
                  <p>
                    <strong>Estado</strong><br></br>
                    {modalData.estado}
                  </p>
                )}
                {modalData?.mensaje && (
                  <p>
                    <strong>Mensaje</strong><br></br>
                    {modalData.mensaje}
                  </p>
                )}


                {/* {modalData?.password && (
                            <p>
                                <strong>Contraseña</strong>
                                {modalData.password}
                            </p>
                        )} */}
              </div>
            </div>
          </ModalCard>
        </div>
      </Modal>
      {loadingDescarga ?
        <LoadingCircle />
        : <></>
      }
    </>
  )
}

export default FiniquitosConsulta