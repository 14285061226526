import React from 'react';
import './App.css';
import { Navigate, Outlet, Route, Routes, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Login } from './features/login/Login';
import Header from './components/header/Header';
import Navbar from './components/navbar/Navbar';
import ConsultaEmpleados from './features/consultaEmpleados/ConsultaEmpleados';
import EnvioManual from './features/envioManual/EnvioManual';
import { RecoverPassword } from './features/recoverPassword/RecoverPassword';
import Home from './features/home/home';
import ChangePassword from './features/changePassword/changePassword';
import CargaMasiva from './features/cargaMasiva/CargaMasiva';
import ContratosConsulta from './features/consultaEmpleados/ContratosConsulta';
import AnexosConsulta from './features/consultaEmpleados/AnexosConsulta';
import FiniquitosConsulta from './features/consultaEmpleados/FiniquitosConsulta';
import ContratosEnvio from './features/envioManual/ContratosEnvio';
import AnexosEnvio from './features/envioManual/AnexosEnvio';
import FiniquitosEnvio from './features/envioManual/FiniquitosEnvio';
import { getToken } from './utils/authHelper';
import Configuracion from './features/configuracion/Configuracion';
import Usuario from './features/configuracion/configuraciones/Usuario';
import Sociedad from './features/configuracion/configuraciones/Sociedad';
import Correo from './features/configuracion/configuraciones/Correo';
import Proceso from './features/configuracion/configuraciones/Proceso';
import Credenciales from './features/configuracion/configuraciones/Credenciales';
import RequestInterceptor from './utils/requestInterceptor';

function App() {
  let message: string = "Hola Mundo";
  const path = useLocation().pathname;
  const navigate = useNavigate();

  const isLogged = (): boolean => {
    if (getToken()) {
      return true;
    } else {
      return false;
    }
  }

  const renderHeaderAndNavbar = () => {
    if (path !== '/login' && !matchPath("/change-password/:hash", path) && path !== '/recover-password') {
      return (
        <>
          <Header />
          <Navbar />
        </>
      )
    }
  }

  const ProtectedRoute = ({ element }: { element: React.ReactNode }) => {
    return isLogged() ? (
      <>{element}</>
    ) : (
      <Navigate to="/login" replace />
    );
  };

  return (
    <>
      <RequestInterceptor />
      {renderHeaderAndNavbar()}
      <Routes>
        {
          isLogged() ? (
            <Route path="/" element={<Navigate to="/consulta/contratos" />} />
          ) : (
            <Route path="/" element={<Navigate to="/login" />} />
          )
        }
        <Route path='/login' element={<Login />} />
        <Route path='/recover-password' element={<RecoverPassword />} />
        <Route path='/change-password/:hash' element={<ChangePassword />} />
        <Route path='/home' element={<Home />} />
        <Route path='/configuracion' element={<Configuracion />}/>
        <Route path='/configuracion/*' element={<Configuracion />}>
          <Route path='usuario' element={<Usuario />} />
          <Route path='sociedad' element={<Sociedad />} />
          <Route path='correo' element={<Correo />} />
          <Route path='proceso' element={<Proceso />} />
          <Route path='credenciales' element={<Credenciales />} />
        </Route>
        <Route path='/consulta/*' element={<ConsultaEmpleados />}>
          <Route path='contratos' element={<ContratosConsulta />} />
          <Route path='anexos' element={<AnexosConsulta />} />
          <Route path='finiquitos' element={<FiniquitosConsulta />} />
        </Route>
        <Route path='/envio/*' element={<EnvioManual />}>
          <Route path='contratos' element={<ContratosEnvio />} />
          <Route path='anexos' element={<AnexosEnvio />} />
          <Route path='finiquitos' element={<FiniquitosEnvio />} />
        </Route>
        <Route path='/carga' element={<CargaMasiva />} />
      </Routes>
    </>
  );
}

export default App;
