import Axios from 'axios';
import { getToken } from './authHelper';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../components/customButton/CustomButton';

const RequestInterceptor = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate()

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleLogin = () => {
        console.log("Redirigiendo al inicio de sesión");
        navigate("/login")
        handleCloseDialog();
    };

    Axios.interceptors.request.use((config) => {
        const token = getToken();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    });

    Axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === 401) {
                console.log("ERROR TOKEN");
                setOpenDialog(true);
            } else {
                console.log(error);
            }
            return Promise.reject(error);
        }
    );

    return (
        <>
            {/* Otros componentes de tu aplicación */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>¡No autorizado!</DialogTitle>
                <DialogContent>
                    <p>No estás autorizado. Por favor, vuelve a iniciar sesión.</p>
                </DialogContent>
                <DialogActions>
                    <CustomButton
                        text="Ir a Iniciar Sesión"
                        type="secondary"
                        handleClick={handleLogin}
                        width='236px'
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RequestInterceptor;