import React, { useEffect, useState } from 'react'
import './TabbedMenu.css'
import { useNavigate } from 'react-router-dom';

interface IPropsTabbedMenu {
  routeTab: string;
  tabs: string[];
  handleClick: (tab: string) => void;
}

const TabbedMenu = ({routeTab = '', tabs, handleClick}: IPropsTabbedMenu) => {
  const [selectedTab, setSelectedTab] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTab(routeTab)
  })

  const handleTab = (value: string) => {
    setSelectedTab(value);
    handleClick(value);
    navigate(value)
  }

  const renderTabs = (tabs: string[]) => {
    return tabs.map((tab: string) => {
      return (
        <button
          className={`tabbed-menu-tab ${tab === selectedTab ? 'tabbed-menu-tab-active' : ''}`}
          key = { tab }
          onClick={(() => handleTab(tab))}
        > 
          { tab }
        </button >
      )
    })
  }

return (
  <div className="tabbed-menu">
    {renderTabs(tabs)}
  </div>
)
}

export default TabbedMenu