import React, { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react'
import './CustomSelect.css'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import DropDownArrow from '../../assets/icons/DropDownArrow';

interface selectOpcion {
    label: string;
    id: string;
}

interface IPropsCustomSelect {
    options: selectOpcion[];
    onSelect: (e: any) => void;
    label: string;
}

export interface CustomSelectRef {
  clear?: () => void;
}

const CustomSelect = forwardRef<CustomSelectRef | undefined, IPropsCustomSelect>(
  ({ options, onSelect, label }: IPropsCustomSelect, ref?: ForwardedRef<CustomSelectRef | undefined>)=> {
    const [value, setValue] = useState("")

    const renderOptions = (items: selectOpcion[]) => {
        return items.map((o: selectOpcion) => {
            return <MenuItem key={o.id} value={o.id}>{o.label}</MenuItem>
        });
    }

    useImperativeHandle(ref, () => ({
      clear: handleClear
    }))

    const handleClear = () => {
      setValue("")
      onSelect("")
    }

    const handleChange = (e: SelectChangeEvent) => {
        setValue(e.target.value);
        onSelect(e.target.value);
    }

    return (
        <div className="custom-select">
            {!value ? <p>{label}</p> : null}
            <Select
                labelId="estado-dropdown"
                label="Estado"
                onChange={handleChange}
                value={value}
                fullWidth
                IconComponent={() => <DropDownArrow />}
                sx={{
                    boxShadow: "none !important",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                    "&.MuiInputBase-root": {
                        paddingRight: '9px',
                        height: '40px',
                        color: '#424242'
                    }
                  }}
            >
                {renderOptions(options)}
            </Select>
        </div>
  )
});

export default CustomSelect