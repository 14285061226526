import BaseService from "./baseService";
import { LoginDto } from "./dto/request/loginDto";
import { RecoverRequestDto } from "./dto/request/recoverRequestDto";
import { RequestDto } from "./dto/request/requestDto";
import { ResetPwRequestDto } from "./dto/request/resetPwRequestDto";
import { BaseResponseDto } from "./dto/response/baseResponseDto";
import { LoginResponseDto } from "./dto/response/loginResponseDto";
import { RecoverResponseDto } from "./dto/response/recoverResponseDto";

class AuthService extends BaseService {
    private resource = "/Auth";

    public login(dto: LoginDto, postLogin: (responseDto: LoginResponseDto) => void, errorLogin:(errorMessage: string) => void): void {
        const reqParams = new RequestDto(this.resource + "/Login", dto);
        this.doPost<LoginResponseDto>(reqParams, postLogin, errorLogin);
    }

    public recoverPassword(dto: RecoverRequestDto, postRecover: (responseDto: RecoverResponseDto) => void, errorRecover:(errorMessage: string) => void): void {
        const reqParams = new RequestDto(this.resource + "/SendEmailResetPassword", dto);
        this.doPost<RecoverResponseDto>(reqParams, postRecover, errorRecover);
    }

    public resetPassword(dto: ResetPwRequestDto, postRecover: (responseDto: BaseResponseDto) => void, errorRecover:(errorMessage: string) => void): void {
        const reqParams = new RequestDto(this.resource + "/ResetPassword", dto);
        this.doPost<BaseResponseDto>(reqParams, postRecover, errorRecover);
    }
}

export default new AuthService();