import BaseService from "./baseService";
import { AnexosCambiarEstado, AnexosRequestDto } from "./dto/request/anexosRequestDto";
import { FiniquitoCambiarEstadoDto, FiniquitoRequestDto } from "./dto/request/finiquitoRequestDto";
import { RequestDto } from "./dto/request/requestDto";
import { AnexoItemResponseDto, AnexoResponseDto, AnexosFiltersResponseDto, AnexosResponseDto } from "./dto/response/anexosResponseDto";
import { BaseResponseDto } from "./dto/response/baseResponseDto";
import { FiniquitoFiltersResponseDto, FiniquitoResponseDto, FiniquitosResponseDto } from "./dto/response/finiquitoResponseDto";

class FiniquitoService extends BaseService {
    private resource = "/Anexo";

    public obtenerTodos(dto: AnexosRequestDto, postRecover: (responseDto: AnexosResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/ObtenerTodos", dto);
        this.doGet<AnexosResponseDto>(reqParams, postRecover, errorRecover);
    }

    public obtener(id: number, postRecover: (responseDto: AnexoResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/Obtener", {id: id});
        this.doGet<AnexoResponseDto>(reqParams, postRecover, errorRecover);
    }

    public descargar(dto: AnexosRequestDto, postRecover: (responseDto: any) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/Descargar", dto, {});
        this.doGet<any>(reqParams, postRecover, errorRecover);
    }

    public obtenerFiltros( postRecover: (responseDto: AnexosFiltersResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/ObtenerFiltros", {});
        this.doGet<AnexosFiltersResponseDto>(reqParams, postRecover, errorRecover);
    }

    public cambiarEstado(dto: AnexosCambiarEstado, postRecover: (responseDto: BaseResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/CambiarEstado",dto, {});
        this.doPost<BaseResponseDto>(reqParams, postRecover, errorRecover);
    }

}

export default new FiniquitoService();