import React from 'react'
import './CustomButton.css'
import { Button } from '@mui/material';

interface IPropsCustomButton {
  text: string;
  handleClick: () => void;
  icon?: React.ReactElement;
  type: "primary" | "secondary";
  width?: string;
  fontSize?: string;
  iconPosition?: "left" | "right";
  padding?: string;
  justify?: "center" | "space-between" | "space-around" | "normal";
  variant?: "contained" | "text" | "outlined"
}

const CustomButton = ({ text,
  handleClick,
  icon,
  type = 'primary',
  width = "100%",
  iconPosition = "left",
  fontSize = "16px",
  padding = ' 10px 0 10px 0',
  justify = 'center',
  variant = 'text'
}: IPropsCustomButton) => {

  return (
    <Button
      variant={variant}
      onClick={handleClick}
      className={`custom-button custom-button-${type}`}
      style={{ width: width, fontSize: fontSize, padding: padding, justifyContent: justify }}
    >
      {icon ? <div className={`custom-button-icon custom-button-icon-${iconPosition}`}>{icon}</div> : null}
      {text}
    </Button>
  )
}

export default CustomButton