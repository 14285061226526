import BaseService from "./baseService";
import { RequestDto } from "./dto/request/requestDto";
import { UsuarioDto, UsuarioRequestDto, UsuarioSelectedDto } from "./dto/request/usuarioRequestDto";
import { BaseResponseDto } from "./dto/response/baseResponseDto";
import { IntIdResponseDto, UsuarioResponseDto, UsuariosResponseDto } from "./dto/response/usuarioResponseDto";

class UsuarioService extends BaseService {
    private resource = "/Usuario";

    public obtenerTodos(dto: UsuarioRequestDto, postRecover: (responseDto: UsuariosResponseDto) => void, errorFunc:(errorMessage: string) => void): void {
        const reqParams = new RequestDto(this.resource + "/ObtenerTodos", {});
        this.doGet<UsuariosResponseDto>(reqParams, postRecover, errorFunc);
    }

    public obtener(id: number, postRecover: (responseDto: UsuarioResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/Obtener", {id: id});
        this.doGet<UsuarioResponseDto>(reqParams, postRecover, errorRecover);
    }

    public crear(dto: UsuarioDto, postRecover: (responseDto: IntIdResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/Crear", dto, {});
        this.doPost<IntIdResponseDto>(reqParams, postRecover, errorRecover);
    }

    public actualizar(dto: UsuarioDto, postRecover: (responseDto: BaseResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/Actualizar", dto, {});
        this.doPost<BaseResponseDto>(reqParams, postRecover, errorRecover);
    }

    public eliminar(dto: UsuarioSelectedDto, postRecover: (responseDto: BaseResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/Eliminar",dto, {});
        this.doPost<BaseResponseDto>(reqParams, postRecover, errorRecover);
    }
}

export default new UsuarioService();