import BaseService from "./baseService";
import { FiniquitoCambiarEstadoDto, FiniquitoRequestDto } from "./dto/request/finiquitoRequestDto";
import { RequestDto } from "./dto/request/requestDto";
import { BaseResponseDto } from "./dto/response/baseResponseDto";
import { FiniquitoFiltersResponseDto, FiniquitoResponseDto, FiniquitosResponseDto } from "./dto/response/finiquitoResponseDto";

class FiniquitoService extends BaseService {
    private resource = "/Finiquito";

    public obtenerTodos(dto: FiniquitoRequestDto, postRecover: (responseDto: FiniquitosResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/ObtenerTodos", dto);
        this.doGet<FiniquitosResponseDto>(reqParams, postRecover, errorRecover);
    }

    public obtener(id: number, postRecover: (responseDto: FiniquitoResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/Obtener", {id: id});
        this.doGet<FiniquitoResponseDto>(reqParams, postRecover, errorRecover);
    }

    public descargar(dto: FiniquitoRequestDto, postRecover: (responseDto: any) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/Descargar", dto, {});
        this.doGet<any>(reqParams, postRecover, errorRecover);
    }

    public obtenerFiltros( postRecover: (responseDto: FiniquitoFiltersResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/ObtenerFiltros", {});
        this.doGet<FiniquitoFiltersResponseDto>(reqParams, postRecover, errorRecover);
    }

    public cambiarEstado(dto: FiniquitoCambiarEstadoDto, postRecover: (responseDto: BaseResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/CambiarEstado",dto, {});
        this.doPost<BaseResponseDto>(reqParams, postRecover, errorRecover);
    }

}

export default new FiniquitoService();