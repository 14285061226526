import CircularProgress from "@mui/material/CircularProgress"

export const LoadingCircle = () => {
    return <div
        style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 9999,
        }}>

        <CircularProgress />
    </div>
}