import React, { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react'
import './CustomDatePicker.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

interface IPropsCustomDatePicker {
    onChange?: (e: any) => void;
    placeholder?: string;
}

export interface CustomDatePickerRef {
    clear?: () => void;
}

const CustomDatePicker = forwardRef<CustomDatePickerRef | undefined, IPropsCustomDatePicker>(
                ({onChange, placeholder = 'Fecha'}: IPropsCustomDatePicker, ref?: ForwardedRef<CustomDatePickerRef | undefined>) => {    
    const [value, setValue] = useState<string>()


    const handleChange = (e: any) => {
        setValue(e)
        if (onChange) onChange(e);
    }

    useImperativeHandle(ref, () => ({
        clear: handleClear
      }))
  
      const handleClear = () => {
        setValue("");
        if (onChange) onChange("");
      }

    return (
        <div className="custom-datepicker">
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                    slots={{
                        openPickerIcon: CalendarTodayIcon,
                    }}
                    onChange={handleChange}
                    value={value}
                    slotProps={{
                        textField: {
                            placeholder: placeholder,
                            variant: 'standard',
                            inputProps: {
                                sx: {
                                    height: '40px',
                                    '&::placeholder': {
                                        color: '#424242',
                                        opacity: 1,
                                        paddingLeft: '14px',
                                    },
                                },
                            }
                        }
                    }}
                />
            </LocalizationProvider>
        </div>
    )
});

export default CustomDatePicker