import React, { useEffect, useRef, useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import SendIcon from '@mui/icons-material/Send'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel } from '@mui/x-data-grid'
import CustomButton from '../../components/customButton/CustomButton'
import CustomSelect, { CustomSelectRef } from '../../components/customSelect/CustomSelect'
import MuiDataGrid from '../../components/muiDataGrid/MuiDataGrid'
import { opcionesFiltroEstados, rowsContratos } from '../../mockedInfo'
import { renderEstadoTags } from '../../utils'
import '../consultaEmpleados/ConsultaEmpleados.css'
import { Checkbox, Modal } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchInput, { CustomSearchInputRef } from '../../components/searchInput/SearchInput'
import CustomDatePicker, { CustomDatePickerRef } from '../../components/customDatePicker/CustomDatePicker'
import { AnexosCambiarEstado, AnexosRequestDto } from '../../services/dto/request/anexosRequestDto'
import { AnexoDetalleResponseDto, AnexoItemResponseDto, AnexoResponseDto, AnexosFiltersResponseDto, AnexosResponseDto } from '../../services/dto/response/anexosResponseDto'
import anexoService from '../../services/anexoService'
import { Estados } from '../../utils/estadoEnum'
import { descargarArchivo } from '../../utils/fileHelper'
import { formatDate, formatDateString } from '../../utils/dateHelper'
import ModalCard from '../../components/modalCard/ModalCard'
import { LoadingCircle } from '../../utils/loadings'

const AnexosConsulta = () => {
  const estadoSelectRef = useRef<CustomSelectRef>(null);
  const sociedadSelectRef = useRef<CustomSelectRef>(null);
  const rutEmpleadoInputRef = useRef<CustomSearchInputRef>(null);
  const nombreEmpleadoInputRef = useRef<CustomSearchInputRef>(null);
  const fechaDesdeInputRef = useRef<CustomDatePickerRef>(null);
  const fechaHastaInputRef = useRef<CustomDatePickerRef>(null);
  const [filtro, setFiltro] = useState<AnexosRequestDto>({
    currentPage: 1,
    pageSize: 10,
    orderBy: '',
    desc: false,
    ParaEnvioManual: true,
  });
  const [cantidadTotalAnexos, setCantidadTotalAnexos] = useState<number>()
  const [loadingDescarga, setLoadingDescarga] = useState(false);
  const [estados, setEstados] = useState<{ label: any, id: any }[]>([]);
  const [sociedades, setSociedades] = useState<{ label: string, id: string }[]>([]);
  const [anexos, setAnexos] = useState<AnexoItemResponseDto[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [modalData, setModalData] = useState<AnexoDetalleResponseDto>();
  const [anexosSeleccionados, setAnexosSeleccionados] = useState<number[]>([]);

  useEffect(() => {
    anexoService.obtenerFiltros(postAnexosFilterRecover, failGetAnexos);
    mapearEstados();
  }, []);

  useEffect(() => {
    filtrarAnexos(filtro)
  }, [filtro]);

  const mapearEstados = () => {
    const enumObj = Estados;
    const keys = Object.keys(enumObj).filter(k => typeof enumObj[k as any] === 'number');
    const result = keys.map((key: any) => ({ label: key, id: enumObj[key] })).filter(x => ![2, 3].includes(+x.id))
    setEstados(result)
  }

  const postAnexosFilterRecover = (responseDto: AnexosFiltersResponseDto) => {
    if (!responseDto.hasErrorMessage) {
      const soc = responseDto.sociedades.map(x => ({ label: x, id: x }));
      setSociedades(soc)
    }
  }

  const filtrarAnexos = (filtro: AnexosRequestDto) => {
    anexoService.obtenerTodos(filtro, successGetAnexos, failGetAnexos)
  }

  const successGetAnexos = (responseDto: AnexosResponseDto) => {
    if (!responseDto.hasErrorMessage) {
      setAnexos(responseDto.anexos);
      setCantidadTotalAnexos(responseDto.count);
      console.log("se seteo contratos: " + anexos.length);
    }
  }

  const failGetAnexos = () => { console.log('error') }

  const handleEstadoChange = (e: any) => {
    const updatedFiltro: AnexosRequestDto = {
      ...filtro,
      Estado: e
    }
    updateFiltro(updatedFiltro);
  };

  const handleSociedadChange = (e: any) => {
    const updatedFiltro: AnexosRequestDto = {
      ...filtro,
      Sociedad: e
    }
    updateFiltro(updatedFiltro);
  };

  const handleDatePickerChangeDesde = (e: any) => {
    const formattedDate = e["$d"]?.toISOString().split("T")[0];
    const updatedFiltro: AnexosRequestDto = {
      ...filtro,
      FechaDesde: formattedDate
    }
    updateFiltro(updatedFiltro);
  }

  const handleDatePickerChangeHasta = (e: any) => {
    const formattedDate = e["$d"]?.toISOString().split("T")[0];
    const updatedFiltro: AnexosRequestDto = {
      ...filtro,
      FechaHasta: formattedDate
    }
    updateFiltro(updatedFiltro);
  }

  const handleRutEmpleado = (e: string) => {
    const updatedFiltro: AnexosRequestDto = {
      ...filtro,
      RutTrabajador: e
    }
    updateFiltro(updatedFiltro);
  }

  const handleNombres = (e: string) => {
    const updatedFiltro: AnexosRequestDto = {
      ...filtro,
      Nombres: e
    }
    updateFiltro(updatedFiltro);
  }

  const handlePageChange = (page: number) => {
    const updatedFiltro: AnexosRequestDto = {
      ...filtro,
      currentPage: page + 1
    }
    console.log(updatedFiltro)
    updateFiltro(updatedFiltro)
  }

  const limpiarFiltros = () => {
    if (estadoSelectRef?.current?.clear) estadoSelectRef.current.clear();
    if (sociedadSelectRef?.current?.clear) sociedadSelectRef.current.clear();
    if (rutEmpleadoInputRef?.current?.clear) rutEmpleadoInputRef.current.clear();
    if (nombreEmpleadoInputRef?.current?.clear) nombreEmpleadoInputRef.current.clear();
    if (fechaDesdeInputRef?.current?.clear) fechaDesdeInputRef.current.clear();
    if (fechaHastaInputRef?.current?.clear) fechaHastaInputRef.current.clear();
    updateFiltro({
      currentPage: 1,
      pageSize: 10,
      orderBy: '',
      desc: false,
      ParaEnvioManual: true,
    })
  }

  const descargar = () => {
    setLoadingDescarga(true)
    anexoService.descargar(filtro, postDescarga, failGetDescarga);
  }

  const failGetDescarga = () => {

    setLoadingDescarga(false)
    console.log('Descarga fallida');
  }

  const postDescarga = (response: any) => {
    descargarArchivo(response);
    setLoadingDescarga(false)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    let orderBy = '';
    let desc = false;
    if (model.length > 0) {
      const sortField = model[0].field;
      orderBy = sortField;
      desc = model[0].sort === 'desc';
    }
    const updatedFiltro: AnexosRequestDto = {
      currentPage: 1,
      pageSize: 10,
      orderBy: orderBy,
      desc: desc,
      ParaEnvioManual: true
    }
    updateFiltro(updatedFiltro);
  };

  const updateFiltro = (filtros: AnexosRequestDto) => {
    let updatedFiltro: AnexosRequestDto = {
      ...filtros
    }
    if (!updatedFiltro.Estado) delete updatedFiltro.Estado;
    if (!updatedFiltro.Sociedad) delete updatedFiltro.Sociedad;
    if (!updatedFiltro.FechaDesde) delete updatedFiltro.FechaDesde;
    if (!updatedFiltro.FechaHasta) delete updatedFiltro.FechaHasta;
    if (!updatedFiltro.RutTrabajador) delete updatedFiltro.RutTrabajador;
    if (!updatedFiltro.Nombres) delete updatedFiltro.Nombres;
    if (!updatedFiltro.Apellidos) delete updatedFiltro.Apellidos;
    setFiltro(updatedFiltro);
  }

  const onSelectRow = (e: any, value: any) => {
    if (e.target.checked) {
      setAnexosSeleccionados([...anexosSeleccionados, value.row.id])
    } else {
      setAnexosSeleccionados(anexosSeleccionados.filter(x => x !== value.row.id))
    }
  }

  const enviar = () => {
    if (anexosSeleccionados.length > 0) {
      const dto: AnexosCambiarEstado = {
        estado: Estados['Procesar'],
        anexoIds: anexosSeleccionados
      }
      anexoService.cambiarEstado(dto, postEnviar, () => { console.log('error al enviar datos') })
    }
  }

  const omitir = () => {
    if (anexosSeleccionados.length > 0) {
      const dto: AnexosCambiarEstado = {
        estado: Estados['Omitir'],
        anexoIds: anexosSeleccionados
      }
      anexoService.cambiarEstado(dto, postEnviar, () => { console.log('error al omitir datos') })
    }
  }

  const postEnviar = () => {
    setAnexosSeleccionados([]);
    updateFiltro({
      currentPage: 1,
      pageSize: 10,
      orderBy: '',
      desc: false,
      ParaEnvioManual: true
    })
  }

  const borrarSeleccion = () => {
    setAnexosSeleccionados([]);
  }

  const handleOnClickVer = (params: GridRowParams) => {
    anexoService.obtener(params.row.id, onPostGetById, failGetAnexos)
    setDialogOpen(true)
  }

  const onPostGetById = (response: AnexoResponseDto) => {
    if (!response.hasErrorMessage) {
      setModalData(response.anexo)
    }
  }

  const onCloseDialog = () => {
    setDialogOpen(false)
  }
  const modalVariable = (label: string, value: any) => {
    return (
      value && (
        <p key={label}>
          <strong>{label}</strong><br />
          {value}
        </p>
      )
    )
  }


  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      flex: 1,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: 'updatedAt',
      headerName: 'Fecha de actualización',
      flex: 1,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: 'nombreEmpresa',
      headerName: 'Nombre Sociedad',
      flex: 1,
    },
    {
      field: 'rutEmpresa',
      headerName: 'Rut Sociedad',
      flex: 1,
    },
    {
      field: 'nombres',
      headerName: 'Nombre Trabajador',
      flex: 1,
    },
    {
      field: 'apellidos',
      headerName: 'Apellido Trabajador',
      flex: 1,
    },
    {
      field: 'rutTrabajador',
      headerName: 'Rut Trabajador',
      flex: 1,
    },
    {
      field: 'fechaIniRelaboral',
      headerName: 'Fecha inicio relación laboral',
      flex: 1,
    },
    {
      field: 'fechaFinRelaboral',
      headerName: 'Fecha fin relación laboral',
      flex: 1,
      valueGetter: (params) => formatDateString(params.value),
    },
    {
      field: 'estadorpa',
      headerName: 'Estado',
      renderCell: (params: any) => renderEstadoTags(params.value),
      flex: 1,
    },
    {
      field: 'editar',
      headerName: 'Editar',
      type: 'actions',
      getActions: (params: GridRowParams) => [<Checkbox checked={anexosSeleccionados.includes(params.row.id)} onChange={(e) => onSelectRow(e, params)} />]
    },
    {
      field: 'ver',
      headerName: 'Ver',
      type: 'actions',
      //No se que hace el boton asi que cambiar el getActions acorde cuando se agregue la logica
      getActions: (params: GridRowParams) => [<GridActionsCellItem
        icon={<VisibilityIcon />}
        label="Ver"
        onClick={() => handleOnClickVer(params)}
      />],
    }
  ];

  return (
    <>
      <div className="main">
        <div className="main-toolbar">
          <div className="main-toolbar-filters">
            {/* TODO: Extraer sidebar de filtros */}
            <div className="main-toolbar-header">
              <p>Filtros</p>
            </div>
            <CustomSelect
              ref={estadoSelectRef}
              label="Estado"
              options={estados}
              onSelect={handleEstadoChange}
            />
            <CustomSelect
              ref={sociedadSelectRef}
              label="Sociedad"
              options={sociedades}
              onSelect={handleSociedadChange}
            />
            <CustomDatePicker ref={fechaDesdeInputRef} placeholder="Fecha desde" onChange={handleDatePickerChangeDesde} />
            <CustomDatePicker ref={fechaHastaInputRef} placeholder="Fecha hasta" onChange={handleDatePickerChangeHasta} />
            <SearchInput label="Rut Empleado" onChange={handleRutEmpleado} ref={rutEmpleadoInputRef} />
            <SearchInput label="Nombre Empleado" onChange={handleNombres} ref={nombreEmpleadoInputRef} />
            <div className="main-toolbar-filters-button">
              <CustomButton
                text="Limpiar Filtros"
                icon={<SettingsBackupRestoreIcon />}
                type="secondary"
                iconPosition='right'
                fontSize='12px'
                handleClick={limpiarFiltros}
              />
            </div>
          </div>
          <CustomButton
            text="Descargar tabla"
            icon={<DownloadIcon />}
            type="primary"
            handleClick={descargar}
          />
        </div>
        <div className="datagrid-envio">
          <MuiDataGrid
            columns={columns}
            rows={anexos}
            rowCount={cantidadTotalAnexos}
            onPageChange={handlePageChange}
            onSortModelChange={handleSortModelChange}
          />
          <div className="datagrid-envio-actions">
            <CustomButton
              text="Borrar selección"
              icon={<DeleteIcon />}
              type="secondary"
              handleClick={borrarSeleccion}
              width='236px'
            />
            <CustomButton
              text="Omitir"
              icon={<CloseIcon />}
              type="primary"
              handleClick={omitir}
              width='166px'
            />
            <CustomButton
              text="Enviar"
              icon={<SendIcon />}
              type="primary"
              handleClick={enviar}
              width='166px'
            />
          </div>
        </div>
      </div>
      <Modal open={dialogOpen} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>
          <ModalCard onClose={onCloseDialog}>
            <h2 style={{ alignSelf: 'center', marginBottom: '10px' }}>Anexo trabajador {modalData?.rutTrabajador}</h2>
            <div className="modal-ver-container">
              <div className="modal-data-container">
                {modalData?.updatedAt && (
                  <p>
                    <strong>Actualizado</strong><br></br>
                    {formatDate(modalData.updatedAt)}
                  </p>
                )}
                {modalData?.createdAt && (
                  <p>
                    <strong>Creado</strong><br></br>
                    {formatDate(modalData.createdAt)}
                  </p>
                )}
                {modalVariable('Nombres', modalData?.nombres)}
                {modalVariable('Apellidos', modalData?.apellidos)}
                {modalVariable('Nombre Empresa', modalData?.nombreEmpresa)}
                {modalVariable('RUT Empresa', modalData?.rutEmpresa)}
                {modalData?.fechaIniRelaboral && (
                  <p>
                    <strong>Fecha Inicio Relación Laboral</strong><br></br>
                    {formatDateString(modalData.fechaIniRelaboral)}
                  </p>
                )}
                {modalData?.fechaFinRelaboral && (
                  <p>
                    <strong>Fecha Fin Relación Laboral</strong><br></br>
                    {formatDateString(modalData.fechaFinRelaboral)}
                  </p>
                )}
                {modalData?.fechaModificacion && (
                  <p>
                    <strong>Fecha Fin Relación Laboral</strong><br></br>
                    {formatDateString(modalData.fechaModificacion)}
                  </p>
                )}
                {modalVariable('Comuna Celebración', modalData?.comunaCelebracion)}
                {modalVariable('Campo', modalData?.campo)}
                {modalVariable('Formulario', modalData?.anterior)}
                {modalVariable('Actual', modalData?.actual)}
                {modalVariable('Estado RPA', modalData?.estado)}
                {modalVariable('Mensaje', modalData?.mensaje)}
              </div>
            </div>
          </ModalCard>
        </div>
      </Modal>
      {loadingDescarga ?
        <LoadingCircle />
        : <></>
      }
    </>
  )
}

export default AnexosConsulta