import React, { useEffect, useState } from 'react'
import './Header.css'
import Logo from '../../assets/Logo.svg';
import User from '../../assets/icons/User';
import { Menu, MenuItem } from '@mui/material';
import { deleteToken, getDataToken } from '../../utils/authHelper';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../../package.json';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userName, setUserName] = useState('Usuario');
  const navigate = useNavigate();
  const version = packageJson.version;

  useEffect(() => {
    const dataToken = getDataToken();
    setUserName(dataToken?.Nombres + " " + dataToken?.Apellidos)
  }, [])

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    deleteToken()
    navigate("");
    handleMenuClose();
  };

  return (
    <div className="header">
      <div className="header-logo">
        <img src={Logo} alt="logo dt automation" />
      </div>
      <div className="header-user">
        <p>{userName}</p>
        <div className="user-icon" onClick={handleMenuClick}>
          <User />
          {process.env.REACT_APP_ENV === 'test' ?
            <p>{version} test</p>
            :
            process.env.REACT_APP_ENV === 'development' ?
              <p>{version} dev</p>
              :
              <p>{version}</p>
          }
        </div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default Header