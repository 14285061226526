import { DataGrid, GridColDef, GridColumnVisibilityModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import React, { useState } from 'react'
import './MuiDataGrid.css'

interface IPropsMuiDataGrid {
    columns: GridColDef[];
    rows: Record<string, any>[];
    onSortModelChange?: (model: GridSortModel) => void;
    onPageChange?: (page: number) => void;
    rowCount?: number;
}

const MuiDataGrid = ({columns, rows, onSortModelChange, onPageChange , rowCount = 100}: IPropsMuiDataGrid) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({ id: false , createdAt: false});
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const handleSortModelChangeLocal = (model: GridSortModel) => {
    console.log(model)
    setSortModel(model);
    if(onSortModelChange) {
      setPaginationModel({page: 0, pageSize: 10,})
      onSortModelChange(model);
    }
  }

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel)
    if(onPageChange) onPageChange(newPaginationModel.page);
  };

  const handleColumnVisibilityChange = (params: any) => {
    setColumnVisibilityModel(params.columns);
  };

  const NoRows = () => {
    return (
      <div style={{display:'flex', alignItems: 'center', height: '100%', justifyContent: 'center'}}>
        No existen registros
      </div>
    )
  }

  return (
    <div className="mui-datagrid" style={{height: '100%'}}>
    <DataGrid
      rows={rows}
      columns={columns}
      slots={{
        noRowsOverlay: NoRows,
      }}
      autoHeight
      onColumnVisibilityModelChange={handleColumnVisibilityChange}
      columnVisibilityModel={columnVisibilityModel}
      disableRowSelectionOnClick
      rowCount={rowCount}
      sortModel={sortModel}
      pageSizeOptions={[10]}
      onSortModelChange={handleSortModelChangeLocal}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={handlePaginationModelChange}

    />
  </div>
  )
}

export default MuiDataGrid