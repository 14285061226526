import React from 'react'

const DropDownArrow = () => {
    return (
        <svg width="22" height="11" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.95837 8L13.3221 0.5H0.594587L6.95837 8Z" fill="black" />
        </svg>
    )
}

export default DropDownArrow