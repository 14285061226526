export class BaseResponseDto {
    public count!: number;
    public errors!: Errors[];
    public hasErrorMessage!: boolean;
    public responseBusinessCode!: number;
    public responseMessage!: string;
}

class Errors {
    public key!: string;
    public value!: string;
    public type!: string;
}