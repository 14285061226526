import { LinearProgress } from '@mui/material';
import React, { useEffect } from 'react'
import './ProgressBar.css'


interface IPropsProgressBar {
  progress: number;
}

const ProgressBar = ({progress}: IPropsProgressBar) => {
  return (
    <div className="progress-bar" >
      <p>{progress}%</p>
      <LinearProgress variant="determinate" value={progress} sx={{ width: '100%' }} />
    </div>
  )
}

export default ProgressBar