import axios from "axios";
import { ResponseDto } from "./dto/responseDto";
import { BaseResponseDto } from "./dto/response/baseResponseDto";
import { RequestDto } from "./dto/request/requestDto";

class BaseService {
    private domainEndpoint: string = process.env.REACT_APP_BASE_URL ?? '';

    private getUrlEndpoint(resource: string) {
        return this.domainEndpoint + resource;
    }

    protected async doPost<T extends BaseResponseDto>(dto: RequestDto, postRecover: (responseDto: T) => void, errorRecover:(errorMessage: string) => void): Promise<void> {
        try {
            const {data, status} = await axios.post<T>(this.getUrlEndpoint(dto.resource), dto.data, dto.headers);
            const response = this.GetResponseData<T>(data, status);

            if (response.code >= 400) {
                errorRecover("Hubo un error inesperado.");
            } else if (response.code === 200) {
                response.hasErrorMessage ? errorRecover(response.responseMessage) : postRecover(response.data);
            }

        } catch (error: any) {
            errorRecover("Hubo un error inesperado.");
        }
    }

    protected async doGet<T extends BaseResponseDto>(dto: RequestDto, postRecover: (responseDto: T) => void, errorRecover:(errorMessage: string) => void): Promise<void> {
        try {
            const {data, status} = await axios.get<T>(this.getUrlEndpoint(dto.resource), {params: dto.data});
            const response = this.GetResponseData<T>(data, status);

            if (response.code >= 400) {
                errorRecover("Hubo un error inesperado.");
            } else if (response.code === 200) {
                response.hasErrorMessage ? errorRecover(response.responseMessage) : postRecover(response.data);
            }

        } catch (error: any) {
            errorRecover("Hubo un error inesperado.");
        }
    }

    private GetResponseData<T extends BaseResponseDto>(data: T, status: number): ResponseDto<T> {
        const response = new ResponseDto<T>();
        response.data = data;
        response.code = status;

        if (data) {
            response.hasErrorMessage = data.hasErrorMessage;
            response.responseBusinessCode = data.responseBusinessCode;
            response.responseMessage = data.responseMessage;
            response.errors = data.errors;
        }

        return response;
    }
};

export default BaseService;