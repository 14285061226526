import React, { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react'
import './SearchInput.css'
import SearchIcon from '@mui/icons-material/Search';

interface IPropsSearchInput {
  onChange: (e: string) => void;
  label: string;
}

export interface CustomSearchInputRef {
  clear?: () => void
}

const SearchInput = forwardRef<CustomSearchInputRef | undefined, IPropsSearchInput>(
  ({ label, onChange }: IPropsSearchInput, ref?: ForwardedRef<CustomSearchInputRef | undefined>) => {
  const [searchTerm, setSearchTerm] = useState<string>("")

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    onChange(e.target.value)
  }

  useImperativeHandle(ref, () => ({
    clear: handleClear
  }))

  const handleClear = () => {
    setSearchTerm("");
    onChange("")
  }

  return (
    <div className="search-input">
      <input placeholder={label} value={searchTerm} onChange={handleChange} />
      <div className="search-input-icon"><SearchIcon /></div>
    </div>
  )
});

export default SearchInput