import React, { useEffect, useState } from 'react'
import TabbedMenu from '../../components/tabbedMenu/TabbedMenu'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const ConsultaEmpleados = () => {
  const tabs = ['Contratos', 'Anexos','Finiquitos'];
  const navigate = useNavigate();
  const location = useLocation();
  const [routeTab, setRouteTab] = useState('')

  useEffect(() => {
    if (location.pathname.toLowerCase().includes(tabs[0].toLowerCase())) {
      setRouteTab(tabs[0]);
    } else {
      setRouteTab(tabs[1]);
    }
  }, [])

  const handleClickMenu = (tab: string) => {
    navigate(`./${tab.toLowerCase()}`)
    setRouteTab(tab);
  }

  return (
    <div className="consulta-empleados">
      <TabbedMenu routeTab={routeTab} tabs={tabs} handleClick={(tab) => handleClickMenu(tab)}/>
      <Outlet></Outlet>
    </div>
  )
}

export default ConsultaEmpleados