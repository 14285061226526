import { Box, Button, Checkbox, Container, FormControlLabel, Grid, InputAdornment, TextField, ThemeProvider } from "@mui/material";
import './RecoverPassword.css'
import React, { useState } from 'react';
import logo from './../../assets/Logo.png';
import PersonIcon from '@mui/icons-material/Person';
import AuthService from "../../services/authService";
import { RecoverResponseDto } from "../../services/dto/response/recoverResponseDto";
import { RecoverRequestDto } from "../../services/dto/request/recoverRequestDto";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

export const RecoverPassword = () => {
    const [email, setEmail] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [emailSended, setEmailSended] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const navigate = useNavigate();

    const postRecoverRequest = (responseDto: RecoverResponseDto) => {
        setLoading(false);
        setEmailSended(true);
        setShowErrorMsg(false);
        setTimeout(() => {
            navigate("/");
        }, 3000);
    }

    const handleErrorRecoverRequest = (msg: string) => {
        setErrorMsg(msg);
        setShowErrorMsg(true);
        setLoading(false);
    }

    const recoverPassword = () => {
        const recoverDto = new RecoverRequestDto();
        recoverDto.correo = email;
        setLoading(true);
        AuthService.recoverPassword(recoverDto, postRecoverRequest, handleErrorRecoverRequest);
    }

    const renderError = () => {
        if (showErrorMsg)
            return <div className="errorMsg">{errorMsg}</div>; 
    }

    const renderFields = () => {
        if (emailSended) {
            return <Grid item xs={12}><p>Revise su casilla de correos para continuar con el proceso de recuperación de contraseña.</p></Grid>;
        } else {
            return (<>
                <Grid item xs={12}>
                    <TextField value={email} onChange={(event) => setEmail(event.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" >
                                <PersonIcon style={{color: "#161616"}} />
                                </InputAdornment>
                            ),
                            style: {zIndex: 10, backgroundColor: "white", width:"300px", borderRadius: "10px", height: "36px", opacity: "60%"}
                    }}/>
                </Grid>

                <Grid item xs={12} style={{paddingTop: "30px"}}>
                    <LoadingButton className="sendActionButton" variant="contained" onClick={recoverPassword} loading={isLoading}>Enviar</LoadingButton>
                    <br />
                    {renderError()}
                </Grid>
            </>
            );
        }
    }

    return(
        <Container maxWidth={false} className="recoverContainer">
            <Box className="recoverBox" margin='auto' justifyContent='center'>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <span className="recoverTitle">Recuperar contraseña</span>
                    </Grid>
                    <Grid item xs={12}>
                        <img src={logo} className="logo"/>
                    </Grid>
                    {renderFields()}
                </Grid>
            </Box>
        </Container>
    );
}