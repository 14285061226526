import { format } from 'date-fns';

export const formatDate = (dateString: string) => {
  const formattedDate = format(new Date(dateString), 'dd/MM/yyyy');
  return formattedDate;
};

export const formatDateString = (dateString: string) => {
  const formattedDate = dateString?.split(" ");
  return formattedDate ? formattedDate[0] : '';
};
