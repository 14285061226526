import { BaseResponseDto } from "./response/baseResponseDto";

export class ResponseDto<T>  extends BaseResponseDto {

    public data!: T;
    public code!: number;

    constructor() {
        super();
    }
}

