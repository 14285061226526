import PersonAdd from "@mui/icons-material/PersonAdd";
import "./Configuracion.css"
import { AccessTime, AddBusiness, Assignment, Email } from "@mui/icons-material";
import { Link, NavLink, Outlet } from "react-router-dom";


const Configuracion = () => {

    const configurationItems = [
        {
            title: "ABM Usuario",
            path: "/configuracion/usuario",
            icon: <PersonAdd sx={{ color: 'white' }} />
        },
        {
            title: "ABM Sociedad",
            path: "/configuracion/sociedad",
            icon: <AddBusiness sx={{ color: 'white' }} />
        },
        {
            title: "Correo",
            path: "/configuracion/correo",
            icon: <Email sx={{ color: 'white' }} />
        },
        {
            title: "Proceso",
            path: "/configuracion/proceso",
            icon: <AccessTime sx={{ color: 'white' }} />
        },
        {
            title: "Credenciales",
            path: "/configuracion/credenciales",
            icon: <Assignment sx={{ color: 'white' }} />
        },
    ]

    return (
        <div className="configuracion-container">
            <div className="menu-container">
                <div className="menu">
                    <h3>Configuracion</h3>
                    {configurationItems.map((item, index) => (
                        <NavLink
                            key={index}
                            className={({ isActive, isPending }) => isActive ? "item item-active" : "item"}
                            to={item.path}
                        >
                            {item.icon}
                            {item.title}
                        </NavLink>
                    ))}
                </div>
            </div>
            <Outlet></Outlet>
        </div>
    )
}

export default Configuracion;