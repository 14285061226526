import { LoadingButton } from "@mui/lab";
import { Box, Container, Grid, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import logo from './../../assets/Logo.png';
import './changePassword.css';
import AuthService from "../../services/authService";
import { ResetPwRequestDto } from "../../services/dto/request/resetPwRequestDto";
import { ResponseDto } from "../../services/dto/responseDto";
import { BaseResponseDto } from "../../services/dto/response/baseResponseDto";

const ChangePassword = () => {
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [passwordChangedSuccesfully, setPasswordChangedSuccesfully] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [differentPasswordError, setDifferentePasswordError] = useState(false);
    let { hash } = useParams();

    const navigate = useNavigate();

    const renderError = () => {
        if (showErrorMsg)
            return <div className="errorMsg">{errorMsg}</div>; 
    }

    const changePassword = () => {
        const reqDto = new ResetPwRequestDto();
        reqDto.hash = hash || "";
        reqDto.password = password1;
        reqDto.passwordRepeat = password2;
        setLoading(true);
        AuthService.resetPassword(reqDto, successResetPassword, handleError);
    }

    const successResetPassword = (response: BaseResponseDto) => {
        setShowErrorMsg(false);
        setPasswordChangedSuccesfully(true);
        setLoading(false);
        setTimeout(() => {
            navigate("/login");
        }, 3000);
    }

    const handleError = (msg: string) => {
        setErrorMsg(msg);
        setShowErrorMsg(true);
        setLoading(false);
    }
    
    useEffect(() => {
        setDifferentePasswordError(password2 !== password1);
    }, [password2, password1])

    const getPassword2ErrorMsg = () => {
        return differentPasswordError? "Las passwords deben coincidir." : "";
    };

    const renderFields = () => {
        if (passwordChangedSuccesfully) {
            return <Grid item xs={12}><p>Se cambió la password correctamente.</p></Grid>;
        } else {
            return (<>
                <Grid item xs={12}>
                    <TextField autoComplete="new-password" value={password1} onChange={(event) => setPassword1(event.target.value)} type="password"
                            InputProps={{
                                style: {backgroundColor: "white", width:"300px", borderRadius: "10px", height: "36px", opacity: "60%"}
                            }}
                            label="Ingrese nueva password"
                            InputLabelProps={{ shrink: true }}
                        />
                    <TextField autoComplete="new-password" style={{marginTop: "10px"}} value={password2} onChange={(event) => setPassword2(event.target.value)} helperText={getPassword2ErrorMsg()} type="password" error={differentPasswordError}
                            InputProps={{
                                style: {backgroundColor: "white", width:"300px", borderRadius: "10px", height: "36px", opacity: "60%"}
                            }}
                            label="Repita la nueva password"
                            InputLabelProps={{ shrink: true, error: differentPasswordError }}
                        />
                    
                </Grid>

                <Grid item xs={12} style={{paddingTop: "30px"}}>
                    <LoadingButton disabled={differentPasswordError} className="sendActionButton" variant="contained" onClick={changePassword} loading={isLoading}>Cambiar Password</LoadingButton>
                    <br />
                    {renderError()}
                </Grid>
            </>
            );
        }
    }

    return (
        <Container maxWidth={false} className="changeContainer">
            <Box className="changeBox" margin='auto' justifyContent='center'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <span className="changeTitle">Cambiar contraseña</span>
                    </Grid>
                    <Grid item xs={12}>
                        <img src={logo} className="logo"/>
                    </Grid>
                    {renderFields()}
                </Grid>
            </Box>
        </Container>
    );
};

export default ChangePassword;