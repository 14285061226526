import React, { useRef, useState } from 'react'
import './CargaMasiva.css'
import DownloadIcon from '@mui/icons-material/Download';
import CustomButton from '../../components/customButton/CustomButton';
import UploadIcon from '@mui/icons-material/Upload';
import ModalCard from '../../components/modalCard/ModalCard';
import { Modal } from '@mui/material';
import ProgressBar from '../../components/progressBar/ProgressBar';

const CargaMasiva = () => {
  //Estado modal de ejemplo
  const [open, setOpen] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadFile, setUploadFile] = useState<File | null>();

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event)
    setUploadFile(event.target?.files ? event.target?.files[0] : null)
    console.log(uploadFile)
    const data = new FormData();
    if (uploadFile) data.append("file", uploadFile);
  };

  const handleUploadButtonClick = () => {
    // Hacer clic en el input de tipo archivo cuando se hace clic en el CustomButton
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const descargarPlantillaFiniquitos = () => {

  }

  const descargarPlantillaContratos = () => {
    
  }


  return (
    <div className="carga-masiva">
      <div className="carga-masiva-main">
        <div className="carga-masiva-main-pasos">
          <h3>Pasos</h3>
          <ul>
            <li>1. Descargar plantilla</li>
            <li>2. Completar el excel con los datos de los contratos</li>
            <li>3. Guardar el excel</li>
            <li>4. Hacer click en subir tabla</li>
            <li>5. Elegir el excel en el que se cargaron los contratos</li>
            <li>6. Si esta todo OK se redirigue a la pagina de contratos</li>
            <li>7. Si algo falla, se le avisará con un mensaje de error cual fue el problema y se descargará un nuevo excel</li>
            <li>8. Para solucionar el problema edite el excel que se descargó recientemente y editelo</li>
            <li>9. Vuelva a repetir el paso 4 con el nuevo excel</li>
          </ul>
        </div>
        <div className="carga-masiva-actions">
          <CustomButton
            text="Descargar plantilla contratos"
            icon={<DownloadIcon />}
            type="secondary"
            iconPosition='left'
            fontSize='16px'
            padding='10px 24px'
            justify='normal'
            width='333px'
            handleClick={descargarPlantillaContratos}
          />
          <CustomButton
            text="Descargar plantilla finiquitos"
            icon={<DownloadIcon />}
            type="secondary"
            iconPosition='left'
            fontSize='16px'
            padding='10px 24px'
            justify='normal'
            width='333px'
            handleClick={descargarPlantillaFiniquitos}
          />
        </div>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        onChange={onChangeHandler}
        style={{ display: 'none' }}
      />
      <CustomButton
        text="Subir tabla"
        icon={<UploadIcon />}
        type="primary"
        iconPosition='left'
        width='289px'
        fontSize='16px'
        // Activar modal de ejemplo
        handleClick={handleUploadButtonClick}
      />
      {/* Modal de ejemplo */}
      <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <>
          <ModalCard onClose={() => setOpen(false)}>
            <h3>Subiendo...</h3>
            <ProgressBar progress={20} />
          </ModalCard>
        </>
      </Modal>
    </div>
  )
}

export default CargaMasiva