import { Checkbox, Grid, TextField } from "@mui/material";
import "./Configuraciones.css"
import CustomButton from "../../../components/customButton/CustomButton";
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import MuiDataGrid from "../../../components/muiDataGrid/MuiDataGrid";
import { GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Add, Edit } from "@mui/icons-material";
import { UsuarioDto, UsuarioRequestDto } from "../../../services/dto/request/usuarioRequestDto";
import usuarioService from "../../../services/usuarioService";
import { UsuarioItemResponseDto, UsuariosResponseDto } from "../../../services/dto/response/usuarioResponseDto";
import { Roles } from "../../../utils/rolEnum";
import CustomSelect, { CustomSelectRef } from "../../../components/customSelect/CustomSelect";

const Usuario = () => {
    const rolSelectRef = useRef<CustomSelectRef>(null);
    const [formMode, setFormMode] = useState(false)
    const [usuarios, setUsuarios] = useState<any[]>([])
    const [cantidadTotalUsuarios, setCantidadTotalUsuarios] = useState<number>()
    const [usuariosSeleccionados, setUsuariosSeleccionados] = useState<any[]>([])
    const [newFormData, setNewFormData] = useState<UsuarioDto>({
        nombre: '',
        correo: '',
        apellido: '',
        rolId: undefined,
        rut: ''
    })
    const [inputNombre, setInputNombre] = useState<string>('')
    const [inputCorreo, setInputCorreo] = useState<string>('')
    const [inputApellido, setInputApellido] = useState<string>('')
    const [inputRut, setInputRut] = useState<string>('')
    const [filtro, setFiltro] = useState<UsuarioRequestDto>({
        currentPage: 1,
        pageSize: 10,
        orderBy: '',
        desc: false
    })
    const [roles, setRoles] = useState<{ label: string, id: string }[]>([])

    useEffect(() => {
        mapearRoles();
    }, [])
    
    useEffect(() => {
        const newForm: UsuarioDto = {
            nombre: inputNombre,
            apellido: inputApellido,
            rut: inputRut,
            correo: inputCorreo,
            rolId: newFormData.rolId
        };
        setNewFormData(newForm);
    }, [inputNombre,inputApellido,inputRut,inputCorreo])

    const mapearRoles = () => {
        const enumObj = Roles;
        const keys = Object.keys(enumObj).filter(k => typeof enumObj[k as any] === 'number');;
        const result = keys.map((key: any) => ({ label: key, id: enumObj[key] }))
        setRoles(result)
    }

    useEffect(() => {
        usuarioService.obtenerTodos(filtro, postObtenerTodos, errorLog)
    }, [filtro])

    const errorLog = (error: string) => {
        console.log(error)
    }

    const postObtenerTodos = (response: UsuariosResponseDto) => {
        if (response.usuarios) {
            setUsuarios(response.usuarios)
        }
        setCantidadTotalUsuarios(response.count)
    }

    const handleSortModelChange = (model: GridSortModel) => {
        let orderBy = '';
        let desc = false;
        if (model.length > 0) {
            const sortField = model[0].field;
            orderBy = sortField;
            desc = model[0].sort === 'desc';
        }
        const updatedFiltro: UsuarioRequestDto = {
            currentPage: 1,
            pageSize: 10,
            orderBy: orderBy,
            desc: desc,
        }
        updateFiltro(updatedFiltro);
    };

    const handlePageChange = (page: number) => {
        const updatedFiltro: UsuarioRequestDto = {
            ...filtro,
            currentPage: page + 1
        }
        updateFiltro(updatedFiltro)
    }

    const updateFiltro = (filtro: UsuarioRequestDto) => {
        setFiltro(filtro)
    }

    const guardarForm = () => {
        usuarioService.crear(newFormData, postGuardar, errorLog)
    }

    const postGuardar = () => {
        console.log('guardado');
        setFormMode(false);
        setFiltro({
            currentPage: 1,
            pageSize: 10,
            orderBy: '',
            desc: false
        })
    }

    const onSelectRow = (event: any, params: GridRowParams<any>) => {
        if (event.target.checked) {
            setUsuariosSeleccionados([...usuariosSeleccionados, params.row.id])
        } else {
            setUsuariosSeleccionados(usuariosSeleccionados.filter(x => x !== params.row.id))
        }
    }

    const handleRolChange = (event: number) => {
        const form: UsuarioDto = {
            ...newFormData,
            rolId: event
        } 
        setNewFormData(form)
    }

    const handleNuevoUsuario = () => {
        setFormMode(true)
    }

    const limpiar = () => {
        setInputApellido('')
        setInputNombre('')
        setInputCorreo('')
        setInputRut('')
        if(rolSelectRef?.current?.clear) rolSelectRef.current.clear()
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
        },
        {
            field: 'correo',
            headerName: 'Correo',
            flex: 1,
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            flex: 1,
        },
        {
            field: 'apellido',
            headerName: 'Apellido',
            flex: 1,
        },
        {
            field: 'rut',
            headerName: 'RUT',
            flex: 1,
        },
        {
            field: 'clienteNombre',
            headerName: 'Nombre del cliente',
            flex: 1,
        },
        {
            field: 'editar',
            headerName: ' ',
            type: 'actions',
            getActions: (params: GridRowParams) => [<Checkbox disabled={params.row.bloqueado} checked={usuariosSeleccionados.includes(params.row.id)} onChange={(e) => onSelectRow(e, params)} />]
        },

    ]

    const renderFormCreate = () => {
        return (
            <div className="usuario-form-container">
                <Grid container spacing={5} >
                    {/* Primera fila */}
                    <Grid item xs={6}>
                        <span>Nombre</span>
                        <TextField 
                            placeholder="Ingresar nombres" 
                            value={inputNombre} 
                            onChange={(event: ChangeEvent<any>)=> {
                                setInputNombre(event.target.value)
                            }} 
                            fullWidth 
                            InputLabelProps={{ shrink: false }} />
                    </Grid>
                    <Grid item xs={6}>
                        <span>Apellido</span>
                        <TextField 
                            placeholder="Ingresar Apellidos" 
                            value={inputApellido} 
                            onChange={(event: ChangeEvent<any>) => {
                                setInputApellido(event.target.value)
                            }} 
                            fullWidth 
                            InputLabelProps={{ shrink: false }} />
                    </Grid>
                    <Grid item xs={6}>
                        <span>RUT</span>
                        <TextField 
                            placeholder="Ingresar RUT"
                            value={inputRut} 
                            onChange={(event: ChangeEvent<any>) => {
                                setInputRut(event.target.value)
                            }} 
                            fullWidth 
                            InputLabelProps={{ shrink: false }} />
                    </Grid>
                    <Grid item xs={6}>
                        <span>Email</span>
                        <TextField 
                            placeholder="Ingresar Email" 
                            value={inputCorreo} 
                            onChange={(event: ChangeEvent<any>) => {
                                setInputCorreo(event.target.value)
                            }} 
                            fullWidth 
                            InputLabelProps={{ shrink: false }} />
                        
                    </Grid>
                    <Grid item xs={6}>
                        <span>Rol</span>
                        <CustomSelect
                            ref={rolSelectRef}
                            label="Seleccionar rol"
                            options={roles}
                            onSelect={handleRolChange}
                        />
                    </Grid>

                </Grid>
                <div className="usuario-buttons-container">
                    <CustomButton
                        text="Limpiar"
                        icon={<DeleteIcon />}
                        type="secondary"
                        handleClick={limpiar}
                        width='177px'
                    />
                    <CustomButton
                        text="Guardar"
                        icon={<SaveIcon />}
                        type="primary"
                        handleClick={guardarForm}
                        width='177px'
                    />
                </div>
            </div>
        )
    }


    return (
        <>
            {formMode ? renderFormCreate() :
                <div className="usuario-container">
                    <div className="usuarios-tabla-container">
                        <MuiDataGrid
                            columns={columns}
                            rows={usuarios}
                            rowCount={cantidadTotalUsuarios}
                            onPageChange={handlePageChange}
                            onSortModelChange={handleSortModelChange}
                        />
                    </div>
                    <div className="buttons-container">
                        <CustomButton
                            text="Eliminar"
                            icon={<DeleteIcon style={{ transform: 'scale(1.2)' }} />}
                            type="secondary"
                            handleClick={() => console.log('pepe')}
                            width='166px'
                        />
                        <CustomButton
                            text="Editar"
                            icon={<Edit style={{ transform: 'scale(1.2)' }} />}
                            type="primary"
                            handleClick={() => console.log('pepe')}
                            width='166px'
                        />
                        <CustomButton
                            text="Nuevo"
                            icon={<Add style={{ transform: 'scale(1.2)' }} />}
                            type="primary"
                            handleClick={handleNuevoUsuario}
                            width='166px'
                        />
                    </div>
                </div>
            }
        </>
    )
}

export default Usuario;