import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, GridActionsCellItem, GridCellParams, GridColDef, GridColumnVisibilityModel, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import './ConsultaEmpleados.css';
import CustomSelect, { CustomSelectRef } from '../../components/customSelect/CustomSelect';
import { opcionesFiltroEstados, rowsContratos } from '../../mockedInfo';
import DownloadIcon from '@mui/icons-material/Download';
import CustomButton from '../../components/customButton/CustomButton';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { renderEstadoTags } from '../../utils';
import MuiDataGrid from '../../components/muiDataGrid/MuiDataGrid';
import SearchInput, { CustomSearchInputRef } from '../../components/searchInput/SearchInput';
import CustomDatePicker, { CustomDatePickerRef } from '../../components/customDatePicker/CustomDatePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';
import contratosService from '../../services/contratosService';
import { ContratosRequestDto } from '../../services/dto/request/contratosRequestDto';
import { Contrato, ContratoDetalleResponseDto, ContratoResponseDto, ContratosFiltersResponseDto, ContratosResponseDto } from '../../services/dto/response/contratosResponseDto';
import { FiltrosContratosDto, FiltrosSeleccionadosDto } from './contratosDto';
import { CircularProgress, Modal, SelectChangeEvent } from '@mui/material';
import { descargarArchivo } from '../../utils/fileHelper';
import ModalCard from '../../components/modalCard/ModalCard';
import { formatDate, formatDateString } from '../../utils/dateHelper';
import { Estados } from '../../utils/estadoEnum';
import { LoadingCircle } from '../../utils/loadings';

const ContratosConsulta = () => {
  const estadoSelectRef = useRef<CustomSelectRef>(null);
  const sociedadSelectRef = useRef<CustomSelectRef>(null);
  const rutEmpleadoInputRef = useRef<CustomSearchInputRef>(null);
  const nombreEmpleadoInputRef = useRef<CustomSearchInputRef>(null);
  const fechaDesdeInputRef = useRef<CustomDatePickerRef>(null);
  const fechaHastaInputRef = useRef<CustomDatePickerRef>(null);
  const [cantidadTotalContratos, setCantidadTotalContratos] = useState<number>()
  const [contratos, setContratos] = useState<Contrato[]>([]);
  const [filtros, setFiltros] = useState<FiltrosContratosDto>(new FiltrosContratosDto());
  const [filtrosSeleccionados, setFiltrosSeleccionados] = useState<FiltrosSeleccionadosDto>(new FiltrosSeleccionadosDto());
  const [sociedades, setSociedades] = useState<{ label: string, id: string }[]>([]);
  const [filtro, setFiltro] = useState<ContratosRequestDto>({
    currentPage: 1,
    pageSize: 10,
    orderBy: '',
    desc: false
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingDescarga, setLoadingDescarga] = useState(false);
  const [modalData, setModalData] = useState<ContratoDetalleResponseDto>();
  const [estados, setEstados] = useState<{ label: any, id: any }[]>([]);


  useEffect(() => {
    contratosService.obtenerFiltros(postContratosFilterRecover, failGetContratos);
    mapearEstados();
  }, []);

  const mapearEstados = () => {
    const enumObj = Estados;
    const keys = Object.keys(enumObj).filter(k => typeof enumObj[k as any] === 'number');;
    const result = keys.map((key: any) => ({ label: key, id: enumObj[key] }))
    setEstados(result)
  }

  useEffect(() => {
    filtrarContratos(filtro)
  }, [filtro]);

  const filtrarContratos = (filtro: ContratosRequestDto) => {
    contratosService.getContratosByFilter(filtro, successGetContratos, failGetContratos)
  }

  const handleEstadoChange = (e: any) => {
    const updatedFiltro: ContratosRequestDto = {
      ...filtro,
      Estado: e
    }
    updateFiltro(updatedFiltro);
  };

  const handleSociedadChange = (e: any) => {
    const updatedFiltro: ContratosRequestDto = {
      ...filtro,
      Sociedad: e
    }
    updateFiltro(updatedFiltro);
  };

  const handleDatePickerChangeDesde = (e: any) => {
    const formattedDate = e["$d"]?.toISOString().split("T")[0];
    const updatedFiltro: ContratosRequestDto = {
      ...filtro,
      FechaDesde: formattedDate
    }
    updateFiltro(updatedFiltro);
  }

  const handleDatePickerChangeHasta = (e: any) => {
    const formattedDate = e["$d"]?.toISOString().split("T")[0];
    const updatedFiltro: ContratosRequestDto = {
      ...filtro,
      FechaHasta: formattedDate
    }
    updateFiltro(updatedFiltro);
  }

  const handleRutEmpleado = (e: string) => {
    const updatedFiltro: ContratosRequestDto = {
      ...filtro,
      RutTrabajador: e
    }
    updateFiltro(updatedFiltro);
  }

  const handleNombres = (e: string) => {
    const updatedFiltro: ContratosRequestDto = {
      ...filtro,
      Nombres: e
    }
    updateFiltro(updatedFiltro);
  }

  const handlePageChange = (page: number) => {
    const updatedFiltro: ContratosRequestDto = {
      ...filtro,
      currentPage: page + 1
    }
    updateFiltro(updatedFiltro)
  }

  const limpiarFiltros = () => {
    if (estadoSelectRef?.current?.clear) estadoSelectRef.current.clear();
    if (sociedadSelectRef?.current?.clear) sociedadSelectRef.current.clear();
    if (rutEmpleadoInputRef?.current?.clear) rutEmpleadoInputRef.current.clear();
    if (nombreEmpleadoInputRef?.current?.clear) nombreEmpleadoInputRef.current.clear();
    if (fechaDesdeInputRef?.current?.clear) fechaDesdeInputRef.current.clear();
    if (fechaHastaInputRef?.current?.clear) fechaHastaInputRef.current.clear();
    updateFiltro({
      currentPage: 1,
      pageSize: 10,
      orderBy: '',
      desc: false
    })
  }

  const descargar = () => {
    setLoadingDescarga(true)
    contratosService.descargar(filtro, postDescarga, failGetDescarga);
  }

  const failGetDescarga = () => {

    setLoadingDescarga(false)
    console.log('Descarga fallida');
  }

  const postDescarga = (response: any) => {
    descargarArchivo(response);
    setLoadingDescarga(false)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    let orderBy = '';
    let desc = false;
    if (model.length > 0) {
      const sortField = model[0].field;
      orderBy = sortField;
      desc = model[0].sort === 'desc';
    }
    const updatedFiltro: ContratosRequestDto = {
      currentPage: 1,
      pageSize: 10,
      orderBy: orderBy,
      desc: desc,
    }
    updateFiltro(updatedFiltro);
  };

  const updateFiltro = (filtros: ContratosRequestDto) => {
    let updatedFiltro: ContratosRequestDto = {
      ...filtros
    }
    if (!updatedFiltro.Estado) delete updatedFiltro.Estado;
    if (!updatedFiltro.Sociedad) delete updatedFiltro.Sociedad;
    if (!updatedFiltro.FechaDesde) delete updatedFiltro.FechaDesde;
    if (!updatedFiltro.FechaHasta) delete updatedFiltro.FechaHasta;
    if (!updatedFiltro.RutTrabajador) delete updatedFiltro.RutTrabajador;
    if (!updatedFiltro.Nombres) delete updatedFiltro.Nombres;
    if (!updatedFiltro.Apellidos) delete updatedFiltro.Apellidos;
    setFiltro(updatedFiltro);
  }

  const postContratosFilterRecover = (responseDto: ContratosFiltersResponseDto) => {
    if (!responseDto.hasErrorMessage) {
      if (responseDto.sociedades) {
        const sociedadesMapeadas: { label: string, id: string }[] = responseDto.sociedades.map((x: string) => ({ label: x, id: x }))
        setSociedades(sociedadesMapeadas)
      }
    }
  }

  const successGetContratos = (responseDto: ContratosResponseDto) => {
    if (!responseDto.hasErrorMessage) {
      setContratos(responseDto.contratos);
      setCantidadTotalContratos(responseDto.count);
      console.log("se seteo contratos: " + contratos.length);
    }
  }

  const failGetContratos = () => {
    console.log('error en obtener contratos')
  }
  const handleOnClickVer = (params: GridRowParams) => {
    contratosService.obtener(params.row.id, onPostGetById, failGetContratos)
    setDialogOpen(true)
  }

  const onPostGetById = (response: ContratoResponseDto) => {
    if (!response.hasErrorMessage) {
      setModalData(response.contrato)
    }
  }

  const modalVariable = (label: string, value: any) => {
    return (
      value && (
        <p key={label}>
          <strong>{label}</strong><br />
          {value}
        </p>
      )
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      flex: 1,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: 'updatedAt',
      headerName: 'Fecha de actualización',
      flex: 1,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: 'nombreEmpresa',
      headerName: 'Nombre Sociedad',
      flex: 1,
    },
    {
      field: 'rutEmpresa',
      headerName: 'Rut Sociedad',
      width: 140,
    },
    {
      field: 'nombres',
      headerName: 'Nombre Trabajador',
      flex: 1,
    },
    {
      field: 'apellidos',
      headerName: 'Apellido Trabajador',
      flex: 1,
    },
    {
      field: 'rutTrabajador',
      headerName: 'Rut Trabajador',
      width: 150,
    },
    {
      field: 'fechaIniRelaboral',
      headerName: 'Fecha inicio relación laboral',
      flex: 1,
      valueGetter: (params) => formatDateString(params.value),
    },
    {
      field: 'fechaFinRelaboral',
      headerName: 'Fecha fin relación laboral',
      flex: 1,
      valueGetter: (params) => formatDateString(params.value),
    },
    {
      field: 'estadorpa',
      headerName: 'Estado',
      width: 120,
      renderCell: (params: any) => renderEstadoTags(params.value),
    },
    {
      field: 'ver',
      headerName: 'Ver',
      type: 'actions',
      width: 30,
      resizable: false,
      //No se que hace el boton asi que cambiar el getActions acorde cuando se agregue la logica
      getActions: (params: GridRowParams) => [<GridActionsCellItem
        icon={<VisibilityIcon />}
        label="Delete"
        onClick={() => handleOnClickVer(params)}
      />],
    }
  ];

  const onCloseDialog = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <div className="main">
        <div className="main-toolbar">
          <div className="main-toolbar-filters">
            {/* TODO: Extraer sidebar de filtros */}
            <div className="main-toolbar-header">
              <p>Filtros</p>
            </div>
            <CustomSelect
              ref={estadoSelectRef}
              label="Estado"
              options={estados}
              onSelect={handleEstadoChange}
            />
            <CustomSelect
              ref={sociedadSelectRef}
              label="Sociedad"
              options={sociedades}
              onSelect={handleSociedadChange}
            />
            <CustomDatePicker ref={fechaDesdeInputRef} placeholder="Fecha desde" onChange={handleDatePickerChangeDesde} />
            <CustomDatePicker ref={fechaHastaInputRef} placeholder="Fecha hasta" onChange={handleDatePickerChangeHasta} />
            <SearchInput label="Rut Empleado" onChange={handleRutEmpleado} ref={rutEmpleadoInputRef} />
            <SearchInput label="Nombre Empleado" onChange={handleNombres} ref={nombreEmpleadoInputRef} />
            <div className="main-toolbar-filters-button">
              <CustomButton
                text="Limpiar Filtros"
                icon={<SettingsBackupRestoreIcon />}
                type="secondary"
                iconPosition='right'
                fontSize='12px'
                handleClick={limpiarFiltros}
              />
            </div>
          </div>
          <CustomButton
            text="Descargar tabla"
            icon={<DownloadIcon />}
            type="primary"
            handleClick={descargar}
          />
        </div>
        <MuiDataGrid
          columns={columns}
          rows={contratos}
          rowCount={cantidadTotalContratos}
          onPageChange={handlePageChange}
          onSortModelChange={handleSortModelChange} />
      </div>
      <Modal open={dialogOpen} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>
          <ModalCard onClose={onCloseDialog}>
            <h2 style={{ alignSelf: 'center', marginBottom: '10px' }}>Contrato trabajador {modalData?.rutTrabajador}</h2>
            <div className="modal-ver-container">
              <div className="modal-data-container">
                {modalData?.updatedAt && (
                  <p>
                    <strong>Actualizado</strong><br></br>
                    {formatDate(modalData.updatedAt)}
                  </p>
                )}
                {modalData?.createdAt && (
                  <p>
                    <strong>Creado</strong><br></br>
                    {formatDate(modalData.createdAt)}
                  </p>
                )}
                {modalVariable('Nombres', modalData?.nombres)}
                {modalVariable('Apellidos', modalData?.apellidos)}
                {modalVariable('Archivo Id', modalData?.archivoId)}
                {modalVariable('Archivo Nombre', modalData?.archivo_Nombre)}
                {modalVariable('Nombre Empresa', modalData?.nombreEmpresa)}
                {modalVariable('RUT Empresa', modalData?.rutEmpresa)}
                {modalVariable('RUT Representante Legal', modalData?.rutRepLeg)}
                {modalVariable('Mail Representante Legal', modalData?.mailRepLeg)}
                {modalVariable('Telefono Representante Legal', modalData?.fonoRepLeg)}
                {modalVariable('Domicilio Representante Legal', modalData?.domicilioRepLeg)}
                {modalVariable('Comuna Celebración', modalData?.comunaCelebracion)}
                {modalData?.fechaSuscrpcion && (
                  <p>
                    <strong>Fecha Suscripción</strong><br></br>
                    {formatDateString(modalData.fechaSuscrpcion)}
                  </p>
                )}
                {modalVariable('RIT Trabajador', modalData?.rutTrabajador)}
                {modalVariable('DNI Trabajador', modalData?.dniTrabajador)}
                {modalData?.fechaNacimiento && (
                  <p>
                    <strong>Fecha de Nacimiento</strong><br></br>
                    {formatDateString(modalData.fechaNacimiento)}
                  </p>
                )}
                {modalVariable('Sexo', modalData?.sexo)}
                {modalVariable('Nacionalidad', modalData?.nacionalidad)}
                {modalVariable('Email Trabajador', modalData?.emailTrabajador)}
                {modalVariable('Telefono', modalData?.telefono)}
                {modalVariable('Region', modalData?.region)}
                {modalVariable('Comuna', modalData?.comuna)}
                {modalVariable('Calle', modalData?.calle)}
                {modalVariable('Numero', modalData?.numero)}
                {modalVariable('Departamento', modalData?.dpto)}
                {modalVariable('Cambio de Domicilio', modalData?.cambioDomicilio)}
                {modalVariable('Declaracion de Discapacidad', modalData?.declaracionDiscapacidad)}
                {modalVariable('Declaracion de Invalidez', modalData?.declaracionInvalidez)}
                {modalVariable('Cargo Trabajadores', modalData?.cargoTrabajadores)}
                {modalVariable('Funciones', modalData?.funciones)}
                {modalVariable('Subcontratación', modalData?.subcontratacion)}
                {modalVariable('RUT Empresa Principal', modalData?.rutEmpresaPrincipal)}
                {modalVariable('Estado', modalData?.est)}
                {modalVariable('RUT Empresa Usuario', modalData?.rutEmpresaUsuaria)}
                {modalVariable('Faena Región', modalData?.faenaRegion)}
                {modalVariable('Faena Provincia', modalData?.faenaProvincia)}
                {modalVariable('Faena Comuna', modalData?.faenaComuna)}
                {modalVariable('Faena Calle', modalData?.faenaCalle)}
                {modalVariable('Faena Numero', modalData?.faenaNumero)}
                {modalVariable('Faena Departamento', modalData?.faenaDpto)}
                {modalVariable('Remito Periodo de Pago', modalData?.remPeriodoPago)}
                {modalVariable('Remito Forma de Pago', modalData?.remFormaPago)}
                {modalVariable('Gratificación de Forma de Pago', modalData?.gratFormaPago)}
                {modalVariable('Remito y Asignaciones', modalData?.remYAsignaciones)}
                {modalVariable('Tipo Jornada', modalData?.tipoJornada)}
                {modalVariable('Articulo 38', modalData?.articulo38)}
                {modalVariable('Número de Resolución', modalData?.nroResolucion)}
                {modalData?.fechaResolucion && (
                  <p>
                    <strong>Fecha de Resolución</strong><br></br>
                    {formatDateString(modalData.fechaResolucion)}
                  </p>
                )}
                {modalVariable('Duracion Jornada', modalData?.duracionJornada)}
                {modalVariable('Número de Dias en Distancia a Jornada', modalData?.nroDiasDistJor)}
                {modalVariable('Distribución Jornada', modalData?.distribucionJornada)}
                {modalVariable('Dias de Distribución Jornada', modalData?.diasDistJor)}
                {modalVariable('Otras Estipulaciones', modalData?.otrasEstipulaciones)}
                {modalVariable('Tipo Contrato', modalData?.tipoContrato)}
                {modalData?.fechaIniRelaboral && (
                  <p>
                    <strong>Fecha Inicio Relación Laboral</strong><br></br>
                    {formatDateString(modalData.fechaIniRelaboral)}
                  </p>
                )}
                {modalData?.fechaFinRelaboral && (
                  <p>
                    <strong>Fecha Fin Relación Laboral</strong><br></br>
                    {formatDateString(modalData.fechaFinRelaboral)}
                  </p>
                )}
                {modalVariable('Estado RPA', modalData?.estadorpa)}
                {modalVariable('Mensaje', modalData?.mensaje)}
                {modalVariable('Formulario', modalData?.formulario)}
                {modalVariable('Repetido', modalData?.repetido)}
              </div>
            </div>
          </ModalCard>
        </div>
      </Modal>
      {loadingDescarga ?
        <LoadingCircle />
        : <></>
      }
    </>
  )
}

export default ContratosConsulta