
export class RequestDto {
    public data: any;
    public resource!: string;
    public headers!: any;

    constructor(resource: string, data: any, headers: any = {}) {
        this.data = data;
        this.resource = resource;
        this.headers = headers;
    }
}