import React from 'react'
import './ModalCard.css'
import CloseIcon from '@mui/icons-material/Close'

interface IPropsModalCard {
    children?: React.ReactNode;
    onClose: () => void;
}

const ModalCard = ({children, onClose}: IPropsModalCard) => {
  return (
    <div className="modal-card">
        <CloseIcon onClick={onClose} className="modal-card-close"/>
        {children}
    </div>
  )
}

export default ModalCard