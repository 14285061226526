
import { jwtDecode } from "jwt-decode"

const TOKEN_KEY = "token";

export function setToken(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
}

export function getToken(): string|null {
    return localStorage.getItem(TOKEN_KEY);
}

export function deleteToken(): void {
    localStorage.removeItem(TOKEN_KEY);
}

export function getDataToken(): any {
    const token = getToken();
    if (token) {
        try {
            const data =  jwtDecode(token);
            return data;
        } catch (error) {
            // Maneja errores de decodificación de JWT
            console.error("Error decoding token:", error);
            return null;
        }
    } else {
        return null;
    }
}