import BaseService from "./baseService";
import { ContratosCambiarEstado, ContratosRequestDto } from "./dto/request/contratosRequestDto";
import { RequestDto } from "./dto/request/requestDto";
import { BaseResponseDto } from "./dto/response/baseResponseDto";
import { ContratoResponseDto, ContratosFiltersResponseDto, ContratosResponseDto } from "./dto/response/contratosResponseDto";
import { DownloadFileResponseDto } from "./dto/response/fileResponseDto";

class ContratosService extends BaseService {
    private resource = "/Contrato";

    public getContratos(sucessFunc: (responseDto: ContratosResponseDto) => void, errorFunc:(errorMessage: string) => void): void {
        const reqParams = new RequestDto(this.resource + "/ObtenerTodos", {});
        this.doGet<ContratosResponseDto>(reqParams, sucessFunc, errorFunc);
    }

    public obtener(id: number, postRecover: (responseDto: ContratoResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/Obtener", {id: id});
        this.doGet<ContratoResponseDto>(reqParams, postRecover, errorRecover);
    }


    public getContratosByFilter(dto: ContratosRequestDto, sucessFunc: (responseDto: ContratosResponseDto) => void, errorFunc:(errorMessage: string) => void): void {
        const reqParams = new RequestDto(this.resource + "/ObtenerTodos", dto);
        this.doGet<ContratosResponseDto>(reqParams, sucessFunc, errorFunc);
    }

    public descargar(dto: ContratosRequestDto, postRecover: (responseDto: DownloadFileResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/Descargar", dto, {});
        this.doGet<any>(reqParams, postRecover, errorRecover);
    }

    public obtenerFiltros( postRecover: (responseDto: ContratosFiltersResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/ObtenerFiltros", {});
        this.doGet<ContratosFiltersResponseDto>(reqParams, postRecover, errorRecover);
    }

    public cambiarEstado(dto: ContratosCambiarEstado, postRecover: (responseDto: BaseResponseDto) => void, errorRecover: (errorMessage: string) => void): void  {
        const reqParams = new RequestDto(this.resource + "/CambiarEstado",dto, {});
        this.doPost<BaseResponseDto>(reqParams, postRecover, errorRecover);
    }
}

export default new ContratosService();