import React from 'react'
import './Navbar.css'
import { Link, useLocation } from 'react-router-dom';

interface NavItem {
  name: string;
  title: string;
  path: string;
}

const Navbar = () => {
  const path = useLocation().pathname;

  const navItems: NavItem[] = [
    {
      name: "consulta",
      title: "Consulta de empleados",
      path: "/consulta/contratos",
    },
    // {
    //   name: "envio",
    //   title: "Envío manual de contratos",
    //   path: "/envio/contratos",
    // },
    // {
    //   name: "carga",
    //   title: "Carga masiva de contratos",
    //   path: "/carga",
    // },
    {
      name: "configuracion",
      title: "Configuración",
      path: "/configuracion",
    },
  ];

  const renderLeftMenuItems = () => {
    return navItems
      .filter((n) => n.name !== 'configuracion')
      .map((n: NavItem, index: number) => (
        <Link
          to={n.path}
          key={n.title}
          className={`navbar-item${index + 1 === navItems.length - 1 ? '-last' : ''} ${
            path.includes(n.name) ? "navbar-item-active" : ''
          }`}
        >
          {n.title}
        </Link>
      ));
  };

  const renderRightMenuItems = () => {
    const configuracionItem = navItems.find((n) => n.name === 'configuracion');

    if (configuracionItem) {
      return (
        <Link
          to={configuracionItem.path}
          key={configuracionItem.title}
          className="navbar-item-configuracion"
        >
          {configuracionItem.title}
        </Link>
      );
    }

    return null;
  };

  return (
    <nav className="navbar">
      <div className="navbar-items">
        {renderLeftMenuItems()}
      </div>
      {/* <div className="navbar-items-right">
        {renderRightMenuItems()}
      </div> */}
    </nav>
  );
};

export default Navbar;