export const renderEstadoTags = (estado: string) => {
  const baseStyle = { color: '#FFFFFF', fontSize: '14px', height: '20px', width: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0 }
  const estadoLowerCase = estado.toLowerCase()
  switch (estadoLowerCase) {
    case "error":
      return <div style={{ ...baseStyle, backgroundColor: '#E52328' }}>{estado}</div>
    case "terminado":
      return <p style={{ ...baseStyle, backgroundColor: '#009928' }}>{estado}</p>
    case "omitir":
      return <p style={{ ...baseStyle, backgroundColor: '#440707' }}>{estado}</p>
    case "procesar":
      return <p style={{ ...baseStyle, backgroundColor: '#F2C94C' }}>{estado}</p>
    case "procesando":
      return <p style={{ ...baseStyle, backgroundColor: '#FF8E24' }}>{estado}</p>
  }
}