import { Box, Checkbox, Container, FormControlLabel, Grid, InputAdornment, TextField } from "@mui/material";
import './Login.css'
import React, { useState } from 'react';
import logo from './../../assets/Logo.png';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import AuthService from "../../services/authService";
import { LoginDto } from "../../services/dto/request/loginDto";
import { LoginResponseDto } from "../../services/dto/response/loginResponseDto";
import { setToken } from "../../utils/authHelper";
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";

export const Login = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const navigate = useNavigate();

    const postLogin = (responseDto: LoginResponseDto) => {
        console.log("LOGIN EXITOSO", responseDto);
        setToken(responseDto.token || "");
        setShowErrorMsg(false);
        setLoading(false);
        navigate("/consulta/contratos");
    }

    const errorLogin = (msg: string) => {
        console.log("LOGIN CON ERROR");
        setErrorMsg(msg);
        setShowErrorMsg(true);
        setLoading(false);
    }

    const submitLogin = () => {
        const loginDto = new LoginDto();
        loginDto.correo = userName;
        loginDto.password = password;
        setLoading(true);
        AuthService.login(loginDto, postLogin, errorLogin);
    }

    const renderError = () => {
        if (showErrorMsg)
            return <div className="errorMsg">{errorMsg}</div>; 
    }

    return(
        <Container maxWidth={false} className="loginContainer">
            <Box className="loginBox" margin='auto' justifyContent='center'>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <span className="loginTitle">Iniciar sesión</span>
                    </Grid>
                    <Grid item xs={12}>
                        <img src={logo} className="logo"/>
                    </Grid>
                    <Grid item xs={12} className="inputs-container">

                        <TextField value={userName} onChange={(event) => setUserName(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                    <PersonIcon style={{color: "#161616"}} />
                                    </InputAdornment>
                                ),
                                style: {zIndex: 10, backgroundColor: "white", width:"300px", borderRadius: "10px", height: "36px", opacity: "60%"}
                        }}/>

                        <TextField value={password} onChange={(event) => setPassword(event.target.value)} type="password"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                    <LockIcon style={{color: "#161616"}} />
                                    </InputAdornment>
                                ),
                                style: {backgroundColor: "white", width:"300px", borderRadius: "10px", height: "36px", marginTop: "7px", opacity: "60%"}
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} style={{paddingTop: "15px"}}>
                        <div className="remember">
                        <FormControlLabel control={
                            <Checkbox defaultChecked color="default" />
                        } label={<span>Recordarme</span>}/>
                        </div>
                    </Grid>

                    <Grid item xs={12} style={{paddingTop: "10px"}}>
                        <LoadingButton className="sendActionButton" variant="contained" onClick={submitLogin} loading={isLoading}>Ingresar</LoadingButton>
                        <br />
                        {renderError()}
                    </Grid>

                    <Grid item xs={12} style={{paddingTop: "25px"}} className="forgetPassword">
                        <a href="/recover-password">Olvidó su contraseña?</a>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}